import { Flex, Icon, Skeleton, Text } from '@chakra-ui/react';
import { CheckCircleIcon, ClockIcon, EyeIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GreenhouseStatus } from '../hooks/useApplications';

interface StatusBadgeProps {
  isSubmitted: boolean;
  status?: GreenhouseStatus;
  bgColor?: string;
}

export const StatusBadge = ({ isSubmitted, status, bgColor = 'neutral.100' }: StatusBadgeProps) => {
  const { t } = useTranslation();
  const [statusString, setStatusString] = useState(status);

  useEffect(() => {
    switch (status) {
      case 'Awaiting Review':
        setStatusString(t('StatusBadge_awatingReview', 'Awaiting Review'));
        return;
      case 'Reviewing':
        setStatusString(t('StatusBadge_reviewing', 'Reviewing'));
        return;
      case 'Approved':
        setStatusString(t('StatusBadge_approved', 'Approved'));
        return;
      case 'Rejected':
        setStatusString(t('StatusBadge_rejected', 'Rejected'));
        return;
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const getIcon = () => {
    switch (status) {
      case 'Awaiting Review':
        return <Icon as={ClockIcon} h={4} w={4} aria-hidden="true" mt="1px" />;
      case 'Reviewing':
        return <Icon as={EyeIcon} h={4} w={4} aria-hidden="true" mt="1px" color="warning.500" />;
      case 'Approved':
        return <Icon as={CheckCircleIcon} h={4} w={4} aria-hidden="true" mt="1px" color="positive.700" />;
      case 'Rejected':
        return <Icon as={XCircleIcon} h={4} w={4} aria-hidden="true" mt="1px" color="negative.400" />;
    }
  };

  if (isSubmitted && !status) {
    return (
      <Skeleton borderRadius={'full'}>
        <Flex bgColor="neutral.100" py="1" px="2" borderRadius="9999px" alignItems={'center'} gap="3px">
          <Text fontWeight="semibold" fontSize="xs">
            {t('StatusBadge_loading', 'Loading')}
          </Text>
        </Flex>
      </Skeleton>
    );
  }

  return (
    <Flex bgColor={bgColor} py="1" px="2" borderRadius="9999px" alignItems={'center'} gap="3px">
      {!isSubmitted ? (
        <Text fontWeight="semibold" fontSize="xs">
          {t('StatusBadge_draft', 'Draft')}
        </Text>
      ) : (
        <>
          {getIcon()}
          <Text fontWeight="semibold" fontSize="xs">
            {statusString}
          </Text>
        </>
      )}
    </Flex>
  );
};
