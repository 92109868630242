import { Avatar, Flex, Icon, Link, Menu, MenuButton, MenuItem, MenuList, useColorMode } from '@chakra-ui/react';
import { UserIcon } from '@heroicons/react/20/solid';
import { AntlerLogo } from 'antler-one';
import { LanguagePicker } from 'application-flow/components/LanguagePicker';
import { useAuthContext } from 'application-flow/contexts/AuthContext';
import { useLogout } from 'application-flow/hooks/useLogout';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { currentUser } = useAuthContext();
  const logout = useLogout();

  const { colorMode } = useColorMode();
  const { t, i18n } = useTranslation();

  return (
    <Flex
      alignSelf={'flex-start'}
      w="full"
      minH="20"
      bgColor="neutral.50"
      alignItems={'center'}
      justifyContent="space-between"
      px="10"
      shadow="md"
    >
      <Link href="/">
        <AntlerLogo w="40" color="primary.500" />
      </Link>
      <Flex alignItems={'center'} gap="3">
        <LanguagePicker />
        <Menu placement="bottom-end">
          {/* Avatar/Button */}
          <MenuButton
            bg={colorMode === 'light' ? 'neutral.200' : 'neutral.500'}
            w="8"
            h="8"
            borderRadius={'full'}
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="xs"
            _focus={{
              outline: '2px solid transparent',
              outlineOffset: '2px',
              ring: '2',
              ringColor: colorMode === 'light' ? 'neutral.500' : 'neutral.200'
            }}
          >
            {/* <span className="sr-only">Open user menu</span> */}
            <Avatar
              size="sm"
              src={currentUser?.photoURL as string | undefined}
              bg={'neutral.200'}
              icon={<Icon as={UserIcon} w={'5'} h={'5'} />}
            />
          </MenuButton>
          {/* Dropdown Menu */}

          <MenuList
            mt="1"
            w="48"
            borderRadius="0.375rem"
            bg={colorMode === 'light' ? 'white' : 'neutral.800'}
            py="1"
            _focus={{ outline: '2px solid transparent', outlineOffset: '2px' }}
            shadow={'lg'}
            ring={'1'}
            ringColor={'rgba(255, 255, 255, 0.05)'}
          >
            <MenuItem
              display="block"
              py="2"
              px="4"
              fontSize="sm"
              textColor={colorMode === 'light' ? 'neutral.700' : 'white'}
              _hover={{ bg: colorMode === 'light' ? 'neutral.100' : 'neutral.700' }}
              _focus={{ bg: colorMode === 'light' ? 'neutral.100' : 'neutral.700' }}
              onClick={logout}
            >
              <a>{t('Header_logout', 'Logout')}</a>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
