import { useApolloClient } from '@apollo/client';
import { auth } from 'antler-one';
import { signOut } from 'firebase/auth';

export const useLogout = () => {
  const apolloClient = useApolloClient();
  return async () => {
    await signOut(auth);
    await apolloClient.clearStore();
  };
};
