import { LoadingScreen } from 'antler-one';
import PageHead from 'application-flow/components/PageHead';
import { useAuthContext } from 'application-flow/contexts/AuthContext';
import { useRouter } from 'next/router';
import { ComponentType } from 'react';

export const withAuth = <T extends JSX.IntrinsicAttributes>(
  Component: ComponentType<T>,
  pageMetadata?: { title?: string }
) => {
  const AuthProtected = (hocProps: T) => {
    const router = useRouter();
    const { isAuthLoading, currentUser } = useAuthContext();

    // Redirect to login page if user is not logged in
    if (!isAuthLoading && !currentUser) {
      router.replace('/login');

      return (
        <>
          <PageHead pageTitle={pageMetadata?.title} />
          <LoadingScreen />
        </>
      ); // Temporary loading screen until re-routed to /login
    }

    // Renders a loading screen until auth state is determined, prevents flashing of protected page
    if (isAuthLoading || !currentUser) {
      return (
        <>
          <PageHead pageTitle={pageMetadata?.title} />
          <LoadingScreen />
        </>
      ); // Temporary loading screen until re-routed to /login
    }

    return (
      <>
        <PageHead pageTitle={pageMetadata?.title} />
        <Component {...hocProps} />
      </>
    );
  };

  return AuthProtected;
};
