import { Flex, Icon, IconButton, Link as ChakraLink, Text } from '@chakra-ui/react';
import { ArrowSmallRightIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useIsScreenSize } from 'antler-one';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Banner = () => {
  const { isTabletOrMobile } = useIsScreenSize();
  const [isBannerShown, setIsBannerShown] = useState(true);
  const { t } = useTranslation();

  return (
    <Flex
      display={isBannerShown ? 'flex' : 'none'}
      position="relative"
      minH="14"
      py="3"
      bgColor="neutral.900"
      w="full"
      justify="center"
      align="center"
      px={{ base: '5', md: '0' }}
    >
      {!isTabletOrMobile && (
        <IconButton
          onClick={() => setIsBannerShown(false)}
          position="absolute"
          right={9}
          top={2}
          as="button"
          aria-label="close banner"
          icon={<Icon color="neutral.100" as={XMarkIcon} h={6} w={6} aria-hidden="true"></Icon>}
        />
      )}
      <Flex w="min(95%, 1000px)" justify="center" alignItems="center">
        <Text fontWeight="light" textColor="white" fontSize={{ base: 'xs', md: 'md' }}>
          {isTabletOrMobile
            ? t('Banner_cantWaitToGetStartedMobile', "Can't wait to get started?")
            : t(
                'Banner_cantWaitToGetStarted',
                "Can't wait to get started? Join the Antler Academy, the free online library for startup founders."
              )}
        </Text>
        <ChakraLink href="https://academy.antler.co" isExternal>
          <Flex align="center" pl={isTabletOrMobile ? 2 : 3} _hover={{ color: 'white', textDecoration: 'underline' }}>
            <Text color="white" pr={1} fontWeight="semibold" fontSize={{ base: '0.7rem', md: 'md' }}>
              {isTabletOrMobile
                ? t('Banner_joinTheAntlerAcademy', 'Join the Antler Academy')
                : t('Banner_learnMore', 'Learn more')}
            </Text>

            <Icon color="neutral.100" as={ArrowSmallRightIcon} h={4} w={4} aria-hidden="true" />
          </Flex>
        </ChakraLink>
      </Flex>
    </Flex>
  );
};

export default Banner;
