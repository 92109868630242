import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { LoadingScreen } from 'antler-one';
import { ApplicationCard } from 'application-flow/components/ApplicationCard';
import Banner from 'application-flow/components/Banner';
import { Header } from 'application-flow/components/Header';
import { withAuth } from 'application-flow/hoc/withAuth';
import { useApplications } from 'application-flow/hooks/useApplications';
import { IApplication } from 'application-flow/types/application.types';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DashboardPage: NextPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { redirectToAcademy } = router.query;
  const [redirectToAcademyDialog, setRedirectToAcademyDialog] = useState(false);
  const {
    allApplications,
    activeApplication,
    submittedApplications,
    isApplicationsLoading,
    greenhouseStatusMap,
    hasExistingDraftApplication,
    canSubmitNewApplication,
    isGreenhouseStatusLoading
  } = useApplications();

  const [deleteDraftDialog, setDeleteDraftDialog] = useState(false);
  const { deleteActiveApplication } = useApplications();
  function onClose() {
    setDeleteDraftDialog(false);
  }

  async function deleteDraftApplication() {
    await deleteActiveApplication();
    setDeleteDraftDialog(false);
  }

  useEffect(() => {
    if (redirectToAcademy) setRedirectToAcademyDialog(true);
  }, [redirectToAcademy]);

  const handleRedirectToAcademyDialogClose = () => {
    setRedirectToAcademyDialog(false);
    router.push('/');
  };

  const handleProceedToAcademy = () => {
    setRedirectToAcademyDialog(false);
    router.push('https://academy.antler.co/');
  };

  const handleNewApplication = () => {
    router.push('/application');
  };

  const handleViewOrContinueApplication = ({ application }: { application: IApplication }) => {
    application.isSubmitted
      ? router.push({
          pathname: '/view',
          query: { id: application.id }
        })
      : router.push('/application');
  };

  const handleDeleteApplication = () => {
    setDeleteDraftDialog(true);
  };

  if (isApplicationsLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {/* Header/Navbar */}
      <Header />
      {/* Academy Banner */}
      <Banner />
      {/* Dashboard */}
      <Box w="min(90%, 1000px)" mt="20" mx="auto">
        {/* Dashboard Header */}
        <Flex w="full" justify="space-between" align="center" mb="12">
          <Text fontWeight="bold" fontSize={{ base: 'xl', md: '3xl' }}>
            {t('index.tsx_myApplications', 'My Applications')}
          </Text>
          <Tooltip
            isDisabled={!(!canSubmitNewApplication || hasExistingDraftApplication || isGreenhouseStatusLoading)}
            label={t('index_onlyHaveOnePendingApplication', 'You can only have one pending application at a time')}
            shouldWrapChildren
          >
            <Button
              isDisabled={!canSubmitNewApplication || hasExistingDraftApplication || isGreenhouseStatusLoading}
              colorScheme={'primary'}
              size={{ base: 'sm', md: 'md' }}
              leftIcon={<Icon color="neutral.100" as={PlusIcon} h={4} w={4} aria-hidden="true" />}
              onClick={handleNewApplication}
            >
              {t('index_newApplication', 'New Application')}
            </Button>
          </Tooltip>
        </Flex>

        {/* Empty State */}
        {!allApplications.length && (
          <Flex direction="column" minH="300px" gap="10px" align="start">
            <Text fontWeight="bold" fontSize="xl">
              {t('index_noApplicationsTitle', "You haven't started any applications yet")}
            </Text>
            <Text>
              {t(
                'index_noApplicationsSubtitle',
                'When you fill out your application to an Antler program, your application will automatically save here.'
              )}
            </Text>
            <Tooltip
              isDisabled={!(!canSubmitNewApplication || hasExistingDraftApplication)}
              label={t('index_onlyHaveOnePendingApplication', 'You can only have one pending application at a time')}
              shouldWrapChildren
            >
              <Button
                disabled={!canSubmitNewApplication || hasExistingDraftApplication}
                onClick={handleNewApplication}
                colorScheme="primary"
                variant="link"
                display="flex"
                alignItems="center"
                gap="5px"
              >
                <Icon color="primary.500" as={PlusIcon} h={4} w={4} aria-hidden="true" />
                {t('index_newApplication', 'New Application')}
              </Button>
            </Tooltip>
          </Flex>
        )}
        {/* Applications List - Draft First */}
        {activeApplication && (
          <ApplicationCard
            mb="6"
            application={activeApplication}
            onViewOrContinue={() => handleViewOrContinueApplication({ application: activeApplication })}
            onDelete={handleDeleteApplication}
          />
        )}
        {!!submittedApplications.length &&
          submittedApplications
            .slice(0)
            .reverse()
            .map((app) => (
              <ApplicationCard
                mb="6"
                key={app.id}
                application={app}
                status={greenhouseStatusMap[app.primaryApplicationId ? app.primaryApplicationId : app.id]}
                onViewOrContinue={() => handleViewOrContinueApplication({ application: app })}
                onDelete={handleDeleteApplication}
              />
            ))}
      </Box>

      {/* Redirect to Academy Dialog */}
      <Modal isCentered isOpen={redirectToAcademyDialog} onClose={handleRedirectToAcademyDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('index_goToAcademyDialogTitle', 'Go to Academy')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t(
              'index_goToAcademyDialogMessage',
              'Join the Antler Academy, the free online library for startup founders and access our curated resource library and global community of other founders to grow your startup today.'
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" variant="ghost" mr={3} onClick={handleRedirectToAcademyDialogClose}>
              {t('index_dialogClose', 'Close')}
            </Button>
            <Button colorScheme="primary" onClick={handleProceedToAcademy}>
              {t('index_dialogProceed', 'Proceed')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered isOpen={deleteDraftDialog} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('index_confirmDeleteTitle', 'Confirm delete')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t(
              'index_confirmDeleteMessage',
              'Are you sure you want to delete this draft application? All progress will be lost.'
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose} variant="ghost">
              {t('index_dialogClose', 'Close')}
            </Button>
            <Button
              variant="solid"
              colorScheme={'red'}
              onClick={() => {
                deleteDraftApplication();
              }}
            >
              {t('index_confirm', 'Confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default withAuth(DashboardPage, { title: 'Apply to Antler' });
