import Head from 'next/head';

const PageHead = ({ pageTitle }: { pageTitle?: string }) => {
  return (
    <Head>
      <title> {pageTitle || 'Apply to Antler'} | The World&apos;s Day Zero Investor</title>
      <meta
        name="description"
        content="Build your next company with Antler, a global day zero investor enabling exceptional founders to start great companies, from the very beginning. Apply now!"
      />
      <meta property="og:url" content="https://apply.antler.co" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${pageTitle || 'Apply to Antler'} | The World's Day Zero Investor`} />
      <meta
        property="og:description"
        content="Build your next company with Antler, a global day zero investor enabling exceptional founders to start great companies, from the very beginning. Apply now!"
      />
      <meta
        property="og:image"
        content="https://assets-global.website-files.com/62d19f82a58657afab15127b/6434b3ab54422e4923398562_Antler.co_Social%20Sharing%20Image_1200x630.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="antler.co" />
      <meta property="twitter:url" content="https://apply.antler.co" />
      <meta name="twitter:title" content={`${pageTitle || 'Apply to Antler'} | The World's Day Zero Investor`} />
      <meta
        name="twitter:description"
        content="Build your next company with Antler, a global day zero investor enabling exceptional founders to start great companies, from the very beginning. Apply now!"
      />
      <meta
        name="twitter:image"
        content="https://assets-global.website-files.com/62d19f82a58657afab15127b/6434b3ab54422e4923398562_Antler.co_Social%20Sharing%20Image_1200x630.png"
      />
    </Head>
  );
};

export default PageHead;
