import { Box, BoxProps, Button, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { StatusBadge } from 'application-flow/components/StatusBadge';
import { GreenhouseStatus } from 'application-flow/hooks/useApplications';
import { IApplication } from 'application-flow/types/application.types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useTranslation } from 'react-i18next';

dayjs.extend(advancedFormat);

interface IApplicationCardProps extends BoxProps {
  application: IApplication;
  status?: GreenhouseStatus;
  onViewOrContinue?: (applicationIsSubmitted: boolean, applicationId: string) => void;
  onDelete?: () => void;
}

export const ApplicationCard = ({
  application,
  onViewOrContinue,
  onDelete,
  status,
  ...props
}: IApplicationCardProps) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Flex
        justify="space-between"
        bg="white"
        height="32"
        p="5"
        borderRadius="lg"
        boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
      >
        <Flex direction="column" justify="space-between">
          <Flex direction="column">
            <Text fontWeight="bold" fontSize="lg">
              {application.location === 'DEFAULT'
                ? t('ApplicationCard_programApplication', 'Program Application')
                : t(application.locationTranslation ?? '', application.location)}
            </Text>
            <Text fontWeight="semibold">
              {application.programName === 'DEFAULT'
                ? null
                : t(application.programTranslation ?? '', application.programName)}
            </Text>
          </Flex>
          <Text fontWeight="light" fontSize="xs">
            {application.isSubmitted
              ? `${t('ApplicationCard_dateOfSubmission', 'Date of submission')}: ` +
                dayjs(application.dateSubmitted).format('Do MMMM, YYYY')
              : `${t('ApplicationCard_startDate', 'Start date')}: ` +
                dayjs(application.createdAt).format('Do MMMM, YYYY')}
          </Text>
        </Flex>
        <Flex direction="column" align="end" justify="space-between">
          <StatusBadge isSubmitted={application.isSubmitted} status={status} />
          <Flex gap="2">
            {!application.isSubmitted && !application.isCofounderApplication && (
              <IconButton
                aria-label="Delete application"
                icon={<Icon as={TrashIcon} />}
                _hover={{ bgColor: 'neutral.100' }}
                variant="outline"
                colorScheme="black"
                size="sm"
                onClick={() => {
                  if (onDelete) onDelete();
                  else throw new Error('onDelete callback not provided to ApplicationCard.');
                }}
              />
            )}
            <Button
              size="sm"
              variant="outline"
              colorScheme="black"
              _hover={{ bgColor: 'neutral.100' }}
              onClick={() => {
                if (onViewOrContinue) onViewOrContinue(application.isSubmitted, application.id);
                else throw new Error('onViewOrContinue callback not provided to ApplicationCard.');
              }}
            >
              {application.isSubmitted ? t('ApplicationCard_view', 'View') : t('ApplicationCard_continue', 'Continue')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
